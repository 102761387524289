<template>
  <div>
    <v-dialog :fullscreen="fullScreen" v-model="show" v-if="transaction">
      <v-card elevation="0">
        <v-toolbar dark color="primary" flat style="padding-right: 10px !important">
          <v-toolbar-title class="overline"
            >Transação Nº {{ transaction.transactionNumber }}</v-toolbar-title
          >
          <v-btn icon right absolute dark>
            <v-icon @click="fullScreen ? (fullScreen = false) : (fullScreen = true)">{{
              setIconScreen()
            }}</v-icon>
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card elevation="0">
          <div id="content">
            <v-container>
              <div style="border: 1px grey solid; mardin: 10px; padding: 15px">
                <v-card-title class="overline">Dados do Pedido</v-card-title>
                <v-row class="d-flex justify-center">
                  <v-row>
                    <v-col cols="6">
                      <v-card-text>
                        <h6>Fornecedor:</h6>
                        {{
                          transaction && transaction.supplier
                            ? transaction.supplier.person.corporateName
                            : "TRANSAÇÃO INTERNA"
                        }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="6">
                      <v-card-text>
                        <h6>Fone:</h6>
                        {{
                          transaction.supplier && transaction.supplier.contact
                            ? transaction.supplier.contact.phoneMobile
                            : "-"
                        }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Data Abertura:</h6>
                        {{ formatDate(transaction.createdAt) }}
                      </v-card-subtitle>
                    </v-col>

                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Plano de Pagamento</h6>
                        {{ transaction.paymentPlan }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Status</h6>
                        {{ transaction.status }}
                      </v-card-subtitle>
                    </v-col>

                    <v-col cols="6" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Tipo:</h6>
                        {{ transaction.type }}
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="12" class="mt-n8">
                      <v-card-subtitle>
                        <h6>Endereço</h6>
                      </v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-row>

                <v-divider />

                <span class="overline text-caption ma-4">Produtos</span>
                <v-data-table
                  :loading="loading"
                  :headers="productHeaders"
                  :items="items"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:[`item.unitCostPrice`]="{ item }">
                    <span> {{ formatPrice(item.unitCostPrice) }}</span>
                  </template>
                  <template v-slot:[`item.salePrice`]="{ item }">
                    <span> {{ formatPrice(item.salePrice) }}</span>
                  </template>

                  <template v-slot:[`item.product.stock`]="{ item }">
                    <span>
                      {{
                        item && item.product && item.product.stockControl
                          ? item.product.stock
                          : "Ilimitado"
                      }}</span
                    >
                  </template>
                </v-data-table>
                <br />
                <br />

                <span class="overline text-caption ma-4">Pagamento</span>
                <hr />
                <br />
                <v-data-table
                  :loading="loading"
                  :headers="instalmentHeaders"
                  :items="instalments"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    <span> R$ {{ formatPrice(item.amount) }}</span>
                  </template>
                  <template v-slot:[`item.number`]="{ item }">
                    <span> {{ `${item.frequencyNumber}/${item.frequencyTotal}` }}</span>
                  </template>
                  <template v-slot:[`item.dueDate`]="{ item }">
                    <span> {{ dateFormat(item.dueDate) }}</span>
                  </template>
                </v-data-table>

                <br />
                <v-row>
                  <v-col cols="4">
                    <v-card elevation="0" color="#3c3c3a" dark>
                      <v-card-subtitle>Total Custo</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$
                        {{
                          transaction.type === "ENTRADA_COMPRA"
                            ? formatPrice(transaction.amountCost)
                            : "0,00"
                        }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="0" color="#3c3c3a" dark>
                      <v-card-subtitle>Total Pago</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$
                        {{
                          transaction.type === "ENTRADA_COMPRA"
                            ? formatPrice(transaction.amountPaid)
                            : "0,00"
                        }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card elevation="0" color="#3c3c3a" dark>
                      <v-card-subtitle>Total a Pagar</v-card-subtitle>
                      <v-card-title class="mt-n8"
                        >R$
                        {{
                          transaction.type === "ENTRADA_COMPRA"
                            ? formatPrice(
                                transaction.amountLiquid - transaction.amountPaid
                              )
                            : "0,00"
                        }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  props: ["transaction", "address", "items", "instalments", "visible"],
  data: () => ({
    title: "Detalhe da Transação",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Todos os Pedidos",
            to: "/pedbem/delivered-sales",
            permission: "gerentor.sales.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Pedidos em Andamento",
        disabled: true,
        href: "/pedbem/carts",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,

    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "orderNumber",
        filtering: true,
      },
      {
        text: "Status",
        value: "deliveryStatus",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        value: "paymentMethod",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amount",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    productHeaders: [
      {
        text: "Descrição",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Estoque",
        align: "start",
        value: "product.stock",
        filtering: true,
      },
      {
        text: "Custo UN R$",
        value: "unitCostPrice",
        filtering: true,
      },
      {
        text: "Venda UN R$",
        value: "salePrice",
        filtering: true,
      },
    ],
    instalmentHeaders: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
      {
        text: "Valor R$",
        value: "amount",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
