<template>
  <div>
    <stock-transaction-detail
      v-if="transaction"
      :transaction="transaction"
      :visible="transactionDialog"
      :items="items"
      :instalments="instalments"
      @close="transactionDialog = false"
    />
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="invoiceModal" persistent max-width="500">
        <v-card elevation="0">
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="overline">Faturar Compra</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="closeInvoiceModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    outlined
                    v-model="payed.accountId"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    label="Conta"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="payed.email"
                    label="Usuário"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="payed.password"
                    label="Senha"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <v-btn
                    :loading="loadingAction"
                    dark
                    color="green"
                    width="100%"
                    @click="saleInvoice"
                  >
                    FATURAR
                  </v-btn>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-btn dark color="red" width="100%" @click="closeInvoiceModal">
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO Será
          Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                to="/base/stock-transactions/create"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Nova Transação</v-btn
              >
              <v-btn
                dark
                color="#2ca01c"
                to="/base/stock-transactions/create"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="sales"
          :search="search"
          class="elevation-0"
          no-data-text="Sem dados no momento"
          no-results-text="Sem dados para sua busca"
          :header-props="headerProps"
        >
          <template v-slot:[`item.amountLiquid`]="{ item }">
            <span>R$ {{ formatPrice(item.amountLiquid) }}</span>
          </template>

          <template v-slot:[`item.supplier`]="{ item }">
            <span>{{
              item.supplier ? item.supplier.person.corporateName : "TRANSAÇÃO INTERNA"
            }}</span>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ dateFormat(item.createdAt) }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text small @click="openDetail(item)"
              ><v-icon small class="mr-2">mdi-eye</v-icon></v-btn
            >
            <v-btn
              v-if="item.status !== 'FATURADO'"
              text
              small
              :to="`/base/stock-transactions/${item.id}/edit`"
            >
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon
              v-if="item.status !== 'FATURADO'"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
            <v-btn
              v-if="item.status === 'FECHADO'"
              small
              outlined
              color="success"
              @click="openInvoiceModal(item)"
              >Faturar</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import moment from "moment";
import StockTransactionDetail from "./components/stock-transaction-detail.vue";
export default Vue.extend({
  name: "Vendas",
  components: { PageHeader, InternalMenu, StockTransactionDetail },
  data: () => ({
    title: "Trnsações de Estoque",
    headerProps: {
      sortByText: "Ordenar por",
    },
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "gerentor.product.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Produtos",
            to: "/base/products",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Fornecedores",
            to: "/base/suppliers",
            permission: "gerentor.person.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/base/products",
      },
      {
        text: "Estoque",
        disabled: true,
        href: "/base/stock-transactions",
      },
    ],
    deleteDialog: false,
    sales: [],
    accounts: [],
    search: "",
    loading: false,
    loadingAction: false,
    transactionId: "",
    invoiceModal: false,
    transaction: null,
    transactionDialog: false,
    payed: {
      accountId: "",
      email: "",
      password: "",
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Data",
        align: "start",
        value: "createdAt",
        filtering: true,
      },
      {
        text: "Fornecedor",
        align: "start",
        value: "supplier",
        filtering: true,
      },
      {
        text: "Status",
        value: "status",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amountLiquid",
        filtering: true,
      },
      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
    items: [],
    instalments: [],
  }),
  methods: {
    openDetail(item) {
      this.transaction = item;
      this.getTransactionItems();
      this.getInstalments();
      this.transactionDialog = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM/YYYY");
    },
    openInvoiceModal(item) {
      this.transaction = item;
      this.transactionId = item.id;
      this.invoiceModal = true;
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
      this.loading = false;
    },
    closeInvoiceModal() {
      this.payed.email = "";
      this.payed.password = "";
      this.payed.accountId = "";
      this.transaction = null;
      this.transactionId = null;
      this.invoiceModal = false;
    },
    saleInvoice() {
      this.loadingAction = true;
      http
        .put(`gerentor/stock-transactions/${this.transactionId}/invoice`, this.payed)
        .then(
          () => {
            this.loadingAction = false;
            this.snackbar.color = "green";
            this.snackbar.text = "Transação Faturada com Sucesso!";
            this.snackbar.opened = true;
            this.getItems();
            this.closeInvoiceModal();
          },
          () => {
            this.loadingAction = false;
            this.snackbar.color = "red";
            this.snackbar.text = "Houve uma Falha ao Faturar a Trabsação";
            this.snackbar.opened = true;
          }
        );
    },

    deleteOpen(itemId) {
      this.transactionId = itemId;
      this.deleteDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("gerentor/stock-transactions?sort=createdAt,DESC").then(
        (data) => {
          this.sales = data.data;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`gerentor/stock-transactions/${this.transactionId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    async getTransactionItems() {
      const saleId = this.transaction.id;
      await http.get(`gerentor/stock-transaction/${saleId}/items`).then((response) => {
        this.items = response?.data;
      });
    },

    async getInstalments() {
      const saleId = this.transaction.id;
      await http
        .get(`finance/transactions?filter=purchaseId||eq||${saleId}`)
        .then((transaction) => {
          this.instalments = transaction.data;
        });
    },
  },
  mounted() {
    this.getItems();
    this.getAccounts();
  },
});
</script>
